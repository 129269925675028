import React from "react";

import { Link, Trans } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import { graphql } from "gatsby";

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NotFoundPage = () => {
  return (
    <Layout pagename="404">
      <SEO />
      <div className="page_content not_found">
        <h2>
          <Trans>¡Oh no!</Trans>
        </h2>
        <p>
          <Trans>Lo sentimos, parece que la página que buscas no existe.</Trans>
        </p>
        <p>
          <Trans i18nKey="Puedes volver a la página inicial o ponerte en contacto si no encuentras lo que buscas.">
            Puedes volver a la <Link to="/">página inicial</Link> o ponerte en
            contacto si no encuentras lo que buscas.
          </Trans>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
